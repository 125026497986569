import { useState } from "react";
import axios from "axios";

export default function SubscriberForm() {
  const [email, setEmail] = useState("");

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      Email: email,
    };
    axios
      .post(
        "https://sheet.best/api/sheets/bbf254a3-2955-4319-bef5-7a642a32b1e1",
        data
      )
      .then((response) => {
        console.log(response);
        setEmail("");
      });
  };

  return (
    <div className="bg-gray-800 py-20 px-4 mx-auto max-w-screen">
      <div class="mx-auto max-w-screen-md sm:text-center">
        <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-300 sm:text-4xl">
          Sign Up for latest Releases
        </h2>
        <p className="mx-auto mb-8 max-w-2xl font-light text-gray-400 sm:text-xl">
          Stay up to date with the latest releases on rizumu, announcements and
          exclusive discounts!
        </p>
        <form onSubmit={handleSubmit}>
          <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
            <div className="relative w-full">
              <label
                htmlFor="email"
                className="hidden mb-2 text-sm font-medium text-gray-300"
              >
                Email Address
              </label>
              <input
                className="block p-3  w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                type="email"
                placeholder="Enter your email"
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <button className="bg-gradient-to-r from-sky-300 to-cyan-400 py-3 px-5 w-full text-sm font-medium text-center rounded-lg border cursor-pointer text-black">
                Subscribe
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
