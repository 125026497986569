import "./App.css";
import Layout from "./components/Layout";
import logoTran from "./images/logo_tran.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import Switcher from "./components/Switcher";
import SubscriberForm from "./components/Subscriber_Form";
import Services from "./components/Services";

// import Music from "./components/Music";

function App() {
  return (
    <Layout>
      <div className="mainContainer">
        <Switcher />
        <div class="p-12">
          <img src={logoTran} alt="" class="imageContainer" />
        </div>

        {/* <Music /> */}
        {/* <div className=" min-w-full flex items-center justify-center bg-black">
          <img
            src={moons}
            alt=""
            className="h-52 md:w-2/5 md:h-1/4 bg-transparent"
          />
        </div> */}

        <div class="flex flex-row space-x-8 p-12 md:pt-4">
          <button>
            <a href=" https://rizumurec.bandcamp.com/album/dub-curtain">
              Bandcamp
            </a>
          </button>
          <button>
            <a href="https://www.beatport.com/label/rizumu-records/112988">
              Beatport
            </a>
          </button>
        </div>

        {/* <div class="flex flex-col items-center justify-center text-center p-12 space-y-4 bg-black rounded-sm">
            <h1 class="text-3xl text-gray-300 font-bold">Lorem</h1>
            <p class="max-w-2xl leading-normal tracking-wide spa text-sm text-gray-300 font-light">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quibusdam temporibus exercitationem facilis eveniet nostrum expedita, vel ut deleniti, quia facere quasi eos repellendus rerum odio, modi ab ullam laudantium tempore!</p>
        </div> */}

        <a href="https://instagram.com/rizumu.rec?igshid=YmMyMTA2M2Y=">
          <FontAwesomeIcon
            icon={faInstagram}
            className="text-gray-800 text-2xl my-4 dark:text-gray-200"
          />
        </a>
      </div>

      <SubscriberForm />

      {/* <Services /> */}
    </Layout>
  );
}

export default App;
