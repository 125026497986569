export default function Footer() {
  return (
    <>
      <footer class="bg-zinc-800 dark:bg-zinc-600 text-center lg:text-left text-xs font-bold">
        <div class="text-gray-200 text-center p-2">
          © 2022 Copyright:
          <a class="text-gray-200" href="rizumurec.com">
            Mizu Prod.
          </a>
        </div>
      </footer>
    </>
  );
}
